// Boxes
	$mobile: 480px; // 480
	$tablet: 768px;
	$large: 952px;
	$all: 90%;

	// customized
	$fif-hund: 500px;
// END Boxes

	$vpath: '/assets';

	$arrow: url('#{$vpath}/images/arrow.png');

	$sprite: url('#{$vpath}/images/sprite.png');

	$body_image: '#{$vpath}/images/bg-body.jpg';


// Elements
	$footer-height: 100px;
// END Elements
