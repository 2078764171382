// Imports
	@import '_variables';
	@import '_mixins';
// END Imports

// Extends
	.dd-normal {
    float: left;
    width: 100%;
    height: auto;
	}

	.dd-normal-box {
    width: $large;
    margin: 0 auto;

    @media only screen and (max-width: $large) {
    	width: $all;
    }
	}

	.normal-second {
		float: left;
		width: 100%;
	}

	.back {
		background-repeat: no-repeat;
		background-image: $sprite;
	}

	.dd-sizing {
		-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
				box-sizing: border-box;
	}

	.abscenter {
		left: 0;
		right: 0;
		margin: 0 auto;
	}

	.reset-sel {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    -moz-appearance: none;
    text-indent: .01px;
    text-overflow: '';
    background-position: right center;
    background-repeat: no-repeat;
		width: 100%;
	}

	.box-sel {
    width: 100px;
		overflow: hidden;
	}

	.pattern-sel {
		// @include border-radius(20px);
		// border: 1px solid $gray-light;
		// color: $gray-light;
		padding: 8px 10px;
    background-position: 90% center;
    font-size: 12px;
	}
// END Extends
