// Fonts
	$font-path: '/build/assets/fonts';

	@font-face {
	  font-family: 'Montserrat-Light';
	  src: url('#{$font-path}/Montserrat-Light.eot');
	  src: url('#{$font-path}/Montserrat-Light.eot?#iefix') format('embedded-opentype');
	  src: local('Montserrat-Light'),
	    url('#{$font-path}/Montserrat-Light.woff2') format('woff2'),
	    url('#{$font-path}/Montserrat-Light.woff') format('woff'),
	    url('#{$font-path}/Montserrat-Light.ttf') format('truetype');
	  font-weight: normal;
	  font-style: normal;
	}

	@font-face {
	  font-family: 'Montserrat-Regular';
	  src: url('#{$font-path}/Montserrat-Regular.eot');
	  src: url('#{$font-path}/Montserrat-Regular.eot?#iefix') format('embedded-opentype');
	  src: local('Montserrat-Regular'),
	    url('#{$font-path}/Montserrat-Regular.woff2') format('woff2'),
	    url('#{$font-path}/Montserrat-Regular.woff') format('woff'),
	    url('#{$font-path}/Montserrat-Regular.ttf') format('truetype');
	  font-weight: normal;
	  font-style: normal;
	}

	@font-face {
	  font-family: 'Montserrat-Bold';
	  src: url('#{$font-path}/Montserrat-Bold.eot');
	  src: url('#{$font-path}/Montserrat-Bold.eot?#iefix') format('embedded-opentype');
	  src: local('Montserrat-Bold'),
	    url('#{$font-path}/Montserrat-Bold.woff2') format('woff2'),
	    url('#{$font-path}/Montserrat-Bold.woff') format('woff'),
	    url('#{$font-path}/Montserrat-Bold.ttf') format('truetype');
	  font-weight: normal;
	  font-style: normal;
	}

	$font-light: 'Montserrat-Light', Arial, sans-serif;
	$font: 'Montserrat-Regular', Arial, sans-serif;
	$font-bold: 'Montserrat-Bold', Arial, sans-serif;
// END Fonts
