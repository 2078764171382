// Colors

  // default
  $black: #000;
  $black-opace: rgba($black, .75);

  $footer-bg: #ccc;

  $transparent: transparent;

  $white: #fff;

  // main
  $main: $black;
  $main-link: $black;
  $main-link-hover: $black-opace;

  $orange: #f98100;
  $orange-dark: #e77a05;

  $brown: #580900;
  $lightbrown: #d0aba6;

  $green: #36ae22;

  $gray: #676767;

  $dark-blue: #102b4e;
// End Colors
