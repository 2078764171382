@import '_colors';

// Mixins
	@mixin border-radius($radius) {
	  -webkit-border-radius: $radius;
	     -moz-border-radius: $radius;
	      -ms-border-radius: $radius;
	          border-radius: $radius;

		@content;
	}

	@mixin fade {
		-webkit-transition: all 0.2s linear;
			-moz-transition: all 0.2s linear;
				-ms-transition: all 0.2s linear;
					-o-transition: all 0.2s linear;
						transition: all 0.2s linear;
	}

	@mixin any($property, $expression) {
	  -webkit-#{$property}: #{$expression};
	  -moz-#{$property}: #{$expression};
	  -ms-#{$property}: #{$expression};
		-o-#{$property}: #{$expression};
		#{$property}: #{$expression};
	}

	@mixin calc($property, $expression) {
		#{$property}: calc( #{$expression} );
    #{$property}: -webkit-calc( #{$expression} );
    #{$property}: -moz-calc( #{$expression} );
	}

	@mixin placeholder($property , $expression) {
		#{$property}: #{$expression} !important;

    &::-webkit-input-placeholder {
        #{$property}: #{$expression};
    }

    &:-moz-placeholder {
        #{$property}: #{$expression};
    }

    &::-moz-placeholder {
        #{$property}: #{$expression};
    }

    &:-ms-input-placeholder {
        #{$property}: #{$expression};
    }
	}

	@mixin textshadow($left : 2px, $top : 2px, $degrade : 9px, $color : $preto) {
		-webkit-text-shadow: $left $top $degrade $color;
		  -moz-text-shadow: $left $top $degrade $color;
		    text-shadow: $left $top $degrade $color;
	}

	@mixin degrade($color1, $color2) {
		background: $color1;
    background: -moz-linear-gradient(top, $color1 0%, $color2 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, $color1), color-stop(100%, $color2));
    background: -webkit-linear-gradient(top, $color1 0%, $color2 100%);
    background: -o-linear-gradient(top, $color1 0%, $color2 100%);
    background: -ms-linear-gradient(top, $color1 0%, $color2 100%);
    background: linear-gradient(to bottom, $color1 0%, $color2 100%);
    // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$color1}', endColorstr='#{$color2}', GradientType=0 );
	}
// END Mixins
