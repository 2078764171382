// Imports
  @import 'modules/_fonts';
  @import 'modules/_all';
// END Imports

.dd-section-bg {
  max-width: 2000px;
  margin: 0 auto;
}

// Slider
////////////////////////
.dd-section-main-slider {
  position: relative;
  max-height: 523px;
  overflow: hidden;
  visibility: hidden;

  .bx-controls {
    position: absolute;
    width: 100%;
    bottom: 0;
    text-align: center;
  }
}

.dd-main-bxslider{
  li {
    overflow: hidden;
    height: 52vw;
    max-height: 523px;

    @media screen and (min-width: 1000px){
      height: 523px;
    }
  }

  li img {
    max-width: none;
    max-height: 100%;
    position: absolute;
    top: -9999px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    margin: auto;
  }
}

.dd-loader-banner {
  background: url(/assets/images/loader.gif) center center no-repeat;
  width: 44px;
  height: 44px;
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 50%;
  margin-top: -22px;
}

/* PAGER */

.bx-wrapper .bx-pager {
  background: $white;
  height: 21px;
	text-align: center;
	font-size: .85em;
	font-family: Arial;
	font-weight: bold;
	color: #666;
  display: inline-block;
  vertical-align: bottom;
}

.bx-wrapper .bx-controls::before {
  background: url(/assets/images/bg-corner-pager-slider.png) left top no-repeat;
  content: "";
  width: 26px;
  height: 21px;
  display: inline-block;
  vertical-align: bottom;
}

.bx-wrapper .bx-controls::after {
  background: url(/assets/images/bg-corner-pager-slider.png) right top no-repeat;
  content: "";
  width: 26px;
  height: 21px;
  display: inline-block;
  vertical-align: bottom;
}

.bx-wrapper .bx-pager .bx-pager-item,
.bx-wrapper .bx-controls-auto .bx-controls-auto-item {
	display: inline-block;
	*zoom: 1;
	*display: inline;
}

.bx-wrapper .bx-pager.bx-default-pager a {
	background: #060d18;
	text-indent: -9999px;
	display: block;
	width: 9px;
	height: 9px;
	margin: 5px 2px 0 1px;
	outline: 0;
  @include border-radius(50%);
}

.bx-wrapper .bx-pager.bx-default-pager a:hover{
	background: #060d18;
}

.bx-wrapper .bx-pager.bx-default-pager a.active {
  border: 2px solid #060d18;
  background: $white;
}
// fim Slider
////////////////////////


// Fomulários
////////////////////////
.dd-form-default {

  input.dd-input-default[type="text"],
  input.dd-input-default[type="email"],
  textarea,
  select {
    width: 100%;
    height: 43px;
    font-size: 14px;
    background: #f1f1f1;
    border: 1px solid #d0aba6;
    padding: 0 19px;
    border-radius: 0;
    @include fade;
    @include placeholder ('color', $brown);
  }

  input[type="text"], textarea, select {
    &:focus {
      background: #E6E5E5;
    }
  }

  input[type="checkbox"] {
    vertical-align: middle;
  }

  textarea {
    padding: 16px 16px;
  }

  .dd-check-text {
    @include calc ('width', "100% - 36px");
    color: $brown;
    vertical-align: middle;
    font-size: 14px;
    margin-left: 9px;
  }

  .dd-container-btn-send {
    width: 190px;
    height: 51px;
    text-align: center;
    display: inline-block;
  }

  .dd-btn-send {
    width: 100%;
    background: $orange;
    color: $white;
    font-size: 18px;
    padding: 15px 0;

    &:hover {
      background: #FF992B;
    }
  }

  .dd-form-loader {
    margin-top: 15px;
  }

  .dd-arrow-down {
    background: url(/assets/images/arrow-down.png) center center no-repeat;
    width: 10px;
    height: 9px;
    pointer-events: none;
    position: absolute;
    top: 18px;
    right: 14px;
  }
}

//Lista de erros do formulário
.dd-feedback-list-form {
  font-size: 12px;
  margin-bottom: 16px;

  li {
    margin-bottom: 5px;
  }
}

.dd-form-loader {
  background: url(/assets/images/ajax-loader.gif) center center no-repeat;
  width: 24px;
  height: 24px;
  margin: 0 auto;
}
// fim Lista de erros do formulário


// Formulário de busca
.dd-line-form-search {
  width: 75%;
  padding: 20px 0;

  @media screen and (max-width: 695px) {
    width: 100%;
    padding-bottom: 0;
  }
}

.dd-form-search {
  .dd-date-ico {
    background: url(/assets/images/date-ico.png) center center no-repeat;
    width: 43px;
    height: 26px;
    pointer-events: none;
    position: absolute;
    top: 9px;
    right: 25px;

    @media screen and (max-width: 695px) {
      right: 10px;
    }
  }

  .dd-search-ico {
    background: url(/assets/images/search-ico.png) center center no-repeat;
    width: 43px;
    height: 26px;
    pointer-events: none;
    position: absolute;
    top: 9px;
    right: 10px;
  }

  .dd-col-form {
    width: 50%;

    @media screen and (max-width: 695px) {
      width: 100%;
      margin-bottom: 12px
    }

    &:first-of-type {
      padding-right: 13px;

      @media screen and (max-width: 695px) {
        padding-right: 0;
      }
    }

    &:last-of-type {
      padding-left: 13px;

      @media screen and (max-width: 695px) {
        padding-left: 0;
      }
    }
  }
}
// fim Formulário de busca


// Estrutura do formulário
.dd-line-form {
  width: 100%;
  margin-bottom: 12px;

  &.dd-col-2-form {
    .dd-col-form {
      width: 50%;
      vertical-align: top;

      @media screen and (max-width: $mobile) {
        width: 100%;
      }
    }

    .dd-col-form:nth-child(odd) {
      padding-right: 6px;

      @media screen and (max-width: $mobile) {
        padding-right: 0;
        margin-bottom: 12px;
      }
    }

    .dd-col-form:nth-child(even) {
      padding-left: 6px;

      @media screen and (max-width: $mobile) {
        padding-left: 0;
      }
    }
  }

  &.dd-col-3-form {
    .dd-col-form {
      width: 30%;

      @media screen and (max-width: $tablet) {
        width: 100%;
      }
    }

    .dd-col-form:nth-child(2) {
      padding: 0 5%;

      @media screen and (max-width: $tablet) {
        padding: 0 0;
        margin: 12px 0;
      }
    }
  }
}
// fim Estrutura do formulário

// fim Fomulários
////////////////////////


//POP UP
///////////////
.dd-popup {
    background-color: rgba(0,0,0,0.75);
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 10000;
    display: table;
    text-align: center;
    top: 0;
    left: 0;
}
.dd-all-center {
    display: table-cell;
    vertical-align: middle;
}
.dd-popup img {
    max-width: 90%;
    width: auto!important;
    height: auto!important;
}
.dd-closeit {
    position: absolute;
    width: 33px;
    height: 33px;
    margin-top: -21px;
    margin-left: -5px;
    z-index: 9999;
    background: $orange;
    border-radius: 50%;
    color: $white;
    line-height: 32px;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
}
//fim POP UP
///////////////

// Geral
////////////////////////
@media screen and (min-width: $mobile + 1) {
  .dd-align-padding {
    padding-left: 10px;
    padding-right: 10px;
  }
}

input.dd-error, select.dd-error, textarea.dd-error {
  border: 1px solid #c91919 !important;
}

.dd-padding-section {
  padding-top: 30px;
}

.dd-title-page-orange {
  color: $orange;
  padding-left: 19px;
  font-size: 25px;
  margin-bottom: 27px;
  text-transform: uppercase;
  font-family: $font;
}

//Botões
.dd-btn-default {
  font-size: 16px;
  padding: 7px 14px;
  display: inline-block;
  text-transform: uppercase;
  border-width: 3px;
  border-style: solid;
  text-align: center;
}

.dd-btn-orange {
  color: $orange;
  border-color: $orange;

  &:hover {
    color: $white;
    background: $orange;
  }
}

.dd-btn-blue {
  color: $dark-blue;
  border-color: $dark-blue;

  &:hover {
    color: $white;
    background: $dark-blue;
  }
}

.dd-btn-white {
  color: $white;
  border-color: $white;

  &:hover {
    color: $orange;
    background: $white;
  }
}
//fim Botões

// Lista de boxes de shows
.dd-list-block-df {
  position: relative;
  width: 100%;
  display: inline-block;

  li {
    width: 33.333%;
    padding: 14px;
    margin-bottom: 50px;
    vertical-align: top;

    @media screen and (min-width: $tablet + 1) {
      &:nth-child(3n+1)::after{
        content: '';
        position: absolute;
        @include calc('width', '100% - 28px');
        height: 1px;
        margin-top: 40px;
        background-color: $lightbrown;
      }
    }

    @media screen and (max-width: 712px) {
      width: 50%;
    }

    @media screen and (max-width: $mobile) {
      width: 100%;
      max-width: 300px;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 30px;
      display: block;
      margin: 0 auto 18px auto;
      border-bottom: 1px solid $lightbrown;
    }
  }

  .dd-box-info {
    margin-top: 6px;
    margin-bottom: 12px;
  }

  .dd-title {
    color: $orange;
  }

  .dd-description {
    color: $brown;
    font-size: 16px;
  }
}
// fim Lista de boxes de shows

// Faixa de Fotos
//////////////////////////
.dd-section-strip-images {
  height: 16.6vw;
  min-height: 164px;
  max-height: 334px;
}
// fim Faixa de Fotos
//////////////////////////

.dd-pg-error {
  h1 {
    color: $brown;
    padding: 162px 0;
    font-size: 30px;
    text-align: center;
  }
}

// Paginação
//////////////////////////
.dd-pagination {
  .pagination {
    font-size: 0;
    text-align: center;

    li {
      display: inline-block;
      font-size: 15px;
      color: $white;
      padding: 4px;

      &.active {
        color: $orange;
        font-family: $font;
      }

      a {
        color: $white;
      }
    }
  }
}
// fim Paginação
//////////////////////////


// Mensagem de sucesso do formulário
//////////////////////////
.dd-section-success {
  position: relative;

  &::before {
    content:"";
    background-color: $orange;
    mix-blend-mode: multiply;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
  }

  .dd-box-feedback {
    text-align: center;
    padding: 87px 0px;

    h1 {
      font-size: 28px;
      color: $white;
      margin-bottom: 40px;
    }
  }
}
// fim Mensagem de sucesso do formulário
//////////////////////////


// CK
.dd-ck-content {
  padding: 0 20px;
  margin-bottom: 40px;
  float: left;
  width: 100%;
  height: auto;
  @extend .dd-sizing;
  text-align: justify;
  word-wrap: break-word;

  h1, h3{
    color: $orange;
    font-size: 16px;
    font-weight: bold;
  }

  h2 {
    color: $brown;
    font-size: 15px;
    display: block;
  }

  h3 {
    font-size: 13px;
  }

  p, a, ul, ol{
    color: $gray;
    font-size: 12px;
    padding: 3px 5px;
  }

  p {
    line-height: normal;
  }

  a {
    width: auto !important;
    height: auto !important;
    display: inline-table;

    &:hover {
      color: $orange;
    }
  }

  ul, ol {
    list-style-position: inside;
    line-height: 18px;
  }

  .dd-video {
    width: 100%;
    max-width: 660px;
    height: 58vw;
    max-height: 415px;
    margin: 15px 0;
  }

  img {
    padding: 15px;
    width: auto !important;
    height: auto !important;
    @extend .dd-sizing;
  }

  .dd-iframe-ticket {
    width: 100%;
    max-width: 100% !important;
    height: 530px !important;
    max-height: 530px !important;
    margin: 15px 0;
  }

  iframe {
    width: 100% !important;
    max-width: 660px;
    height: 61vw;
    max-height: 415px;
  }

  .dd-icon-more {
    color: $orange;
    font-family: $font;
  }

  .dd-link-accordion {
    cursor: pointer;
    color: $gray;
    font-size: 13px;
  }

  .dd-accordion-data {
    list-style-type: none;
    padding: 9px 0px;
    display: none;
    padding-left: 15px;
  }

  hr {
    margin: 10px 0;
  }
}
// fim CK


//datepicker
.ui-widget-content {
  background: #eeeeee url("/assets/images/ui-bg_highlight-soft_100_eeeeee_1x100.png") 50% top repeat-x;
}

.ui-widget-header {
  background: #f6a828 url("/assets/images/ui-bg_gloss-wave_35_f6a828_500x100.png") 50% 50% repeat-x;
}

.ui-widget-header .ui-icon {
  background-image: url("/assets/images/ui-icons_ffffff_256x240.png");
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
	background: $orange;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
	background: #f6f6f6 url("/assets/images/ui-bg_glass_100_f6f6f6_1x400.png") 50% 50% repeat-x;
}

.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
	background: #ffe45c url("/assets/images/ui-bg_highlight-soft_75_ffe45c_1x100.png") 50% top repeat-x;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
	background: #ffffff url("/assets/images/ui-bg_glass_65_ffffff_1x400.png") 50% 50% repeat-x;
}

.ui-icon {
  display: block;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat;
}
//end datepicker

// fim Geral
////////////////////////
